'use strict';

angular.module('enervexSalesappApp').factory('SpecificationTemplate', function ($resource) {
	return $resource('/api/specificationtemplates/:id', {
			id: '@_id'
		},
		{

		});
});
